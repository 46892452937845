import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
// import Mailto from 'react-protected-mailto';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  // const toggleHover = () => {
  //   setIsHovered(!isHovered);
  // };

  const onMouseover = () => {
    setIsHovered(true);
  };
  const onMouseout = () => {
    setIsHovered(false);
  };

  const yr = new Date().getFullYear();

  return (
    <footer className='container-fluid text-light bg-dark py-4'>
      <Container>
        <small>
          {/* <h6 className='text-center'>PMchome Logistics</h6> */}

          {/* <Row className='my-3'>
            <Col sm='12' className='text-center'>
              Email:&nbsp; <Mailto email='info@pmchomelogistics.com' />
            </Col>
            <Col sm='12' className='text-center'>
              Tel:&nbsp; <Mailto tel='+255-765-903087' /> |{' '}
              <Mailto tel='+254-707-144542' />
            </Col>
          </Row> */}
          <Row>
            <Col className='text-center my-2'>
              <h6>Find Us</h6>
              <div className='social-links'>
                <Link
                  to={{
                    pathname:
                      'https://www.facebook.com/people/PMchome-Logistics/100089705556969/',
                  }}
                  target='_blank'
                >
                  <AiOutlineFacebook /> Facebook
                </Link>
                {/* <Link to="" className=''><AiOutlineTwitter /> Twitter</Link> */}
                <Link
                  to={{
                    pathname: 'https://www.instagram.com/pmchome_logistics/',
                  }}
                  target='_blank'
                >
                  <AiOutlineInstagram /> Instagram
                </Link>
                <Link
                  to={{ pathname: 'https://wa.me/+255765903087' }}
                  target='_blank'
                >
                  <AiOutlineWhatsApp /> WhatsApp
                </Link>
              </div>
            </Col>
          </Row>

          <Row className='my-4'>
            <Col className='text-center'>
              &copy; <span id='yr'>{yr}</span> &nbsp; PMchome Logistics Company
              Ltd. All rights reserved!
            </Col>
            {/* <Col sm='12' md='4' className='my-2 text-center'>
              Email:&nbsp; <Mailto email='info@pmchomelogistics.com' />
            </Col>
            <Col sm='12' md='4' className='my-2 text-right'>
              Tel:&nbsp; <Mailto tel='+255-765-903087' /> |{' '}
              <Mailto tel='+254-707-144542' />
            </Col> */}
          </Row>
          <Row className='align-items-end mt-4'>
            <Col className='d-flex justify-content-center justify-content-md-end'>
              <div
                className='gss'
                onMouseEnter={onMouseover}
                onMouseLeave={onMouseout}
              >
                {isHovered ? (
                  <a
                    className='gss_l'
                    href='https://gritsoft.systems'
                    target='_BLANK'
                    rel='noopener noreferrer'
                  >
                    GritSoft.Systems
                  </a>
                ) : (
                  <span className='gss_i'>Site Powered By</span>
                )}
              </div>
            </Col>
          </Row>
        </small>
      </Container>
    </footer>
  );
};

export default Footer;
