import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import ScrollToTop from './components/helper/ScrollToTop';
import { Helmet } from 'react-helmet';
import Header from './components/layouts/Header';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import NotFound from './components/pages/NotFound';
import Footer from './components/layouts/Footer';

import SiteState from './context/site/SiteState';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>PMchome Logistics</title>
        <meta name='author' content='Clement Nzau' />
        <meta name='publisher' content='GritSoft.Systems' />
        <meta name='theme-color' content='#F1592A' />
        <meta
          name='description'
          content='PMchome Logistics is the leading regional freight forwarding
          company'
        />
        <meta
          name='keywords'
          content='Freight Forwarding, Clearing and forwarding, Logistics, Customs Brokerage, Trade Consultation, Cargo Transport, Export, Import, Air, Road, Rail, PMchome Logistics'
        />
        <meta name='robots' content='index, follow' />
        <meta name='revisit-after' content='7 days' />
        <meta name='distribution' content='global' />
      </Helmet>
      <SiteState>
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </SiteState>
    </div>
  );
}

export default App;
