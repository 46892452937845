import React, { Fragment, useRef, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SiteContext from './../../context/site/siteContext';
import useScrollSpy from 'react-use-scrollspy';
import Carousel from './../Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { Img010, Check } from '../../assets/images';

import './home.css'

const Home = (props) => {
  const siteContext = useContext(SiteContext);

  const { updateSectRef } = siteContext;

  const sectionRefs = [useRef(null), useRef(null)];

  const activeSect = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -80,
  });

  useEffect(() => {
    updateSectRef(activeSect);
    console.log(activeSect);
  }, [activeSect]);

  return (
    <Fragment>
      <Helmet>
        <title>Home | PMchome Logistics</title>
        <link rel='canonical' href='https://pmchomelogistics.com/home' />
      </Helmet>

      <section ref={sectionRefs[0]}>
        <Carousel />
      </section>

      <Container as='section' ref={sectionRefs[1]}>
        <Row className='height34 align-items-center'>
          <Col sm='12' md='4' className='my-4'>
            <p className='small text-muted'>We are a family not a workforce!</p>
            <h3 className='company-gradient'>
              PMchome Logistics
            </h3>
          </Col>
          <Col>
            <div className='lbd'>
              <p className='mb-3'>
                The leading regional freight forwarding company operating in
                Tanzania, Kenya, Uganda, Rwanda, Burundi, South Sudan, Zambia
                and DRC.
              </p>
              <p className='my-5 lead'>
                We are involved in all fields of freight logistics with the main
                emphasize on project forwarding.
              </p>
              <p className='mt-3 mb-1'>
                <i>Why choose us?</i>
              </p>
                <ul
                  className='check-list'
                  style={{ listStyleImage: `url(${Check})` }}
                >
                  <li>Dependable</li>
                  <li>Innovative</li>
                  <li>Flexible</li>
                </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          backgroundImage: `url(${Img010})`,
          backgroundSize: 'cover',
        }}
      >
        <Container
          as='section'
          ref={sectionRefs[2]}
          fluid={true}
          style={{ backgroundColor: 'rgba(242,89,42,.4)' }}
        >
          <Row className='align-items-center justify-right  px-3 height34'>
            <Col
              sm='12'
              md={{ span: 'auto', offset: 8 }}
              className=' p-2 rounded'
              style={{
                backgroundColor: 'rgba(248,249,250,.85)',
                backgroundSize: 'cover',
              }}
            >
              <b className='text-muted'>Industry Sectors</b>
              <ul style={{ listStyle: 'none', paddingLeft: '0.5rem' }}>
                <li>Agriculture</li>
                <li>Food & Drinks</li>
                <li>Chemical</li>
                <li>Healthcare</li>
                <li>Retail and consumer</li>
                <li>Industrial & Manufacturing</li>
                <li>Oil & Gas</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <h2 className='text-center'>Services</h2>
        <div className='row justify-content-around'>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Air Export & Import</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Ocean Export & Import</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Road Cargo Transport</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Trade Consultation</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Customs Brokerage</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Goods Distribution</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Cargo Consolidation</div>
          </div>
          <div className='col-sm-12 col-md-4 my-3'>
            <div className=' serve-item'>Government Documentation</div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Home;
