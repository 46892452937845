import React, { Fragment, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
// import QuoteForm from './layouts/QuoteForm';
import { Link } from 'react-router-dom';

const Cta = () => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const handleSubmit = () => {
  //   console.log('submit data');
  //   const { form } = this.formRef.props;
  //   form.validateFields((err, values) => {
  //     if (err) {
  //       return;
  //     }

  //     console.log('Received values of form: ', values);
  //     form.resetFields();
  //     handleClose();
  //   });
  // };

  // const saveFormRef = (formRef) => {
  //   this.formRef = formRef;
  // };

  return (
    <Fragment>
      <Link to={{ pathname: '/contact' }} state={{ quote: 'true' }}>
        <Button
          size='lg'
          type='button'
          variant='outline-warning'
          className='btn cta'
          // data-toggle='modal'
          // data-target='#exampleModalCenter'
          // onClick={handleShow}
        >
          Get a Quote
        </Button>
      </Link>

      {/* <Modal show={show} onHide={handleClose} className='modal-success'>
        <Modal.Header closeButton>
          <Modal.Title>Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' size='sm' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' size='sm' onClick={handleSubmit}>
            Request Quote
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Fragment>
  );
};

export default Cta;
