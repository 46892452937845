import React, { Fragment, useState } from 'react';
import Cta from './Cta';
import {
  Img01,
  // Img02,
  Img03,
  Img04,
  // Img05,
  Img06,
  Img07,
} from '../assets/images';
import Carousel from 'react-bootstrap/Carousel';

const Carousel_ = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  return (
    <Fragment>
      <div
        style={{
          height: '100vh',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          display: 'table',
          position: 'absolute',
          zIndex: 1,
        }}
      >
        {/* <div className='c-a'> */}
        <Cta />
        {/* </div> */}
      </div>
      <Carousel
        pauseOnHover={false}
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
      >
        <Carousel.Item style={{ backgroundImage: `url(${Img01})` }}>
          {/* <img className='d-block w-100' src={Img01} alt='First slide' /> */}
          <Carousel.Caption>
            {/* <h3>First slide label</h3> */}
            <p>A leading Regional freight forwading company.</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item style={{ backgroundImage: `url(${Img02})` }}> */}
        {/* <img className='d-block w-100' src={Img02} alt='Third slide' /> */}

        {/* <Carousel.Caption> */}
        {/* <h3>Second slide label</h3> */}
        {/* <p>
              We deliver the finest logistics solutions and service experience.
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item style={{ backgroundImage: `url(${Img03})` }}>
          {/* <img className='d-block w-100' src={Img03} alt='Third slide' /> */}

          <Carousel.Caption>
            {/* <h3>Third slide label</h3> */}
            <p>Operating in Eastern and Central Africa.</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item style={{ backgroundImage: `url(${Img05})` }}> */}
        {/* <img className='d-block w-100' src={Img03} alt='Third slide' /> */}
        {/* <Carousel.Caption> */}
        {/* <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p> */}
        {/* </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item style={{ backgroundImage: `url(${Img06})` }}>
          {/* <img className='d-block w-100' src={Img03} alt='Third slide' /> */}

          <Carousel.Caption>
            {/* <h3>Third slide label</h3> */}
            <p>
              Delivers the finest logistics solutions and service experience.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ backgroundImage: `url(${Img04})` }}>
          {/* <img className='d-block w-100' src={Img03} alt='Third slide' /> */}

          <Carousel.Caption>
            {/* <h3>Third slide label</h3> */}
            <p>Assurers the quality of service.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item style={{ backgroundImage: `url(${Img07})` }}>
          {/* <img className='d-block w-100' src={Img03} alt='Third slide' /> */}

          <Carousel.Caption>
            {/* <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p> */}
            <p>Fast and Savior of Traders.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Fragment>
  );
};

export default Carousel_;
