import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Axios from 'axios';
import { Helmet } from 'react-helmet';
import Mailto from 'react-protected-mailto';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Badge,
  Spinner,
} from 'react-bootstrap';
import AlertModal from '../AlertModal';
import { Link } from 'react-router-dom';
import { AiOutlineFacebook } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const ContactSchema = Yup.object().shape({
  company: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required!'),
  email: Yup.string()
    .email('Email is not valid!')
    .required('Email address is required!'),
  phone: Yup.string()
    .min(4, 'Too Short!')
    .matches(phoneRegExp, 'Phone number is not valid! +255 700 000000')
    .required('Phone number is required!'),
  address: Yup.string().min(5, 'Too Short!'),
  message: Yup.string()
    .min(50, 'Message should have 50 characters and above!')
    .required('Message is required!'),
  // Yup.string().when('project', {
  //   is: '',
  //   then: Yup.string()
  //     .min(50, 'Message should have 50 characters and above!')
  //     .required('Message is required!'),
  //   otherwise: Yup.string().min(
  //     50,
  //     'Message should have 50 characters and above!'
  //   ),
  // }),
  //     .min(50, 'Message should have 50 characters and above!')
  //     .required('Message is required!')
  // : Yup.string().min(50, 'Message should have 50 characters and above!'),
  // project: Yup.string().when('message', {
  //   is: '',
  //   then: Yup.string()
  //     .min(50, 'Project should have 50 characters and above!')
  //     .required('Project is required!'),
  //   otherwise: Yup.string().min(
  //     50,
  //     'Project should have 50 characters and above!'
  //   ),
  // }),
  // ? Yup.string()
  //     .min(50, 'Project should have 50 characters and above!')
  //     .required('Project is required!')
  // : Yup.string().min(50, 'Project should have 50 characters and above!'),
});

const QuoteSchema = Yup.object().shape({
  company: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required!'),
  email: Yup.string()
    .email('Email is not valid!')
    .required('Email address is required!'),
  phone: Yup.string()
    .min(4, 'Too Short!')
    .matches(phoneRegExp, 'Phone number is not valid! +255 700 000000')
    .required('Phone number is required!'),
  address: Yup.string().min(5, 'Too Short!'),
  // message: Yup.string().min(50, 'Message should have 50 characters and above!').required('Message is required!'),
  // Yup.string().when('project', {
  //   is: '',
  //   then: Yup.string()
  //     .min(50, 'Message should have 50 characters and above!')
  //     .required('Message is required!'),
  //   otherwise: Yup.string().min(
  //     50,
  //     'Message should have 50 characters and above!'
  //   ),
  // }),
  //     .min(50, 'Message should have 50 characters and above!')
  //     .required('Message is required!')
  // : Yup.string().min(50, 'Message should have 50 characters and above!'),
  project: Yup.string()
    .min(50, 'Project should have 50 characters and above!')
    .required('Project is required!'),
  // .when('message', {
  //   is: '',
  //   then: Yup.string()
  //     .min(50, 'Project should have 50 characters and above!')
  //     .required('Project is required!'),
  //   otherwise: Yup.string().min(
  //     50,
  //     'Project should have 50 characters and above!'
  //   ),
  // }),
  // ? Yup.string()
  //     .min(50, 'Project should have 50 characters and above!')
  //     .required('Project is required!')
  // : Yup.string().min(50, 'Project should have 50 characters and above!'),
});

const Contact = (props) => {
  let location = useLocation();
  const [quote, setQuote] = useState(
    location.state ? (location.state.quote === 'true' ? true : false) : false
  );
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    location.state
      ? location.state.quote === 'true'
        ? setQuote(true)
        : setQuote(false)
      : setQuote(false);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Contact | PMchome Logistics</title>
        <link rel='canonical' href='https://pmchomelogistics.com/contact' />
      </Helmet>
      <div className='main'>
        <Container>
          <h2 className='text-center mb-4 pt-4'>
            {quote ? `Get a Quote` : `Get In Touch`}
          </h2>
          <Row>
            <Col md={8} className='bg-light pt-2'>
              <Formik
                initialValues={{
                  company: '',
                  name: '',
                  email: '',
                  phone: '',
                  address: '',
                  message: '',
                  project: '',
                }}
                validationSchema={quote ? QuoteSchema : ContactSchema}
                onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                  setSubmitting(true);
                  console.log(values);
                  Axios({
                    method: 'post',
                    url: 'https://pmchomelogistics.com/contact.php',
                    headers: { 'content-type': 'application/json' },
                    data: values,
                  })
                    .then((result) => {
                      console.log('hello');
                      console.log(result);
                      console.log(result.data);
                      if (result.data.sent) {
                        resetForm();
                        setSent(true);
                        setShow(true);
                      } else {
                        setErrors(true);
                        setErrors(result.data.message);
                        // console.log(errors);
                        console.log(result.data.message);
                        setSubmitting(false);
                        setSent(false);
                        setShow(true);
                      }
                    })
                    .catch((error) => {
                      console.log(error.result);
                      setErrors(error.message);
                      // console.log(errors);
                      setSent(false);
                      setShow(true);
                    });

                  setSubmitting(false);
                }}
                render={({
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  isValid,
                  handleSubmit,
                  isSubmitting,
                  errors,
                }) => (
                  <>
                    {show && (
                      <AlertModal
                        status={sent}
                        quote={quote}
                        handleClose={handleClose}
                      />
                    )}
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={12} md={5}>
                          <Form.Group controlId='company'>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              autoFocus
                              size='sm'
                              type='text'
                              value={values.company}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group controlId='name'>
                            <Form.Label>
                              Contact Name{' '}
                              <span className='text-danger'>*</span>
                            </Form.Label>
                            <Form.Control
                              size='sm'
                              type='text'
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.name && touched.name}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group controlId='email'>
                            <Form.Label>
                              Email address{' '}
                              <span className='text-danger'>*</span>
                            </Form.Label>
                            <Form.Control
                              size='sm'
                              type='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.email && touched.email}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group controlId='phone'>
                            <Form.Label>
                              Phone No. <span className='text-danger'>*</span>
                            </Form.Label>
                            <Form.Control
                              size='sm'
                              type='tel'
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.phone && touched.phone}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={7}>
                          <Form.Group controlId='address'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              size='sm'
                              type='text'
                              value={values.address}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group controlId={quote ? 'project' : 'message'}>
                            <Form.Label>
                              {quote ? 'Project' : 'Message'}{' '}
                              <span className='text-danger'>*</span>
                            </Form.Label>
                            <Form.Control
                              size='sm'
                              as='textarea'
                              rows='6'
                              value={quote ? values.project : values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                quote
                                  ? errors.project && touched.project
                                  : errors.message && touched.message
                              }
                            />
                            <Form.Control.Feedback type='invalid'>
                              {quote ? errors.project : errors.message}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Button
                            className='float-right my-3'
                            variant='primary'
                            type='submit'
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <>
                                <Spinner
                                  as='span'
                                  animation='grow'
                                  variant='light'
                                  size='sm'
                                />{' '}
                                {quote ? `Requesting` : `Sending`}
                              </>
                            ) : quote ? (
                              `Request Quote`
                            ) : (
                              `Send Message`
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              />
            </Col>

            <Col md={4} className='contact-widget mt-5 mb-2'>
              <div></div>
              <h6>
                <Badge bg='secondary'>Email:</Badge>
              </h6>
              <p className='mb-4'>
                <Mailto email='info@pmchomelogistics.com' />
              </p>

              <h6>
                <Badge bg='secondary'>Phone:</Badge>
              </h6>
              <ul className='phone-list'>
                <li>
                  <Mailto tel='+255-765-903087' />
                </li>
                <li>
                  <Mailto tel='+254-758-309046' />
                </li>
              </ul>
              <h6>
                <Badge bg='secondary'>Social:</Badge>
              </h6>
              <div className='social-lnks'>
                <Link
                  to={{
                    pathname:
                      'https://www.facebook.com/people/PMchome-Logistics/100089705556969/',
                  }}
                  target='_blank'
                >
                  <AiOutlineFacebook />
                </Link>
                {/* <Link to="" className=''><AiOutlineTwitter /> Twitter</Link> */}
                <Link
                  to={{
                    pathname: 'https://www.instagram.com/pmchome_logistics/',
                  }}
                  target='_blank'
                >
                  <AiOutlineInstagram />
                </Link>
                <Link
                  to={{ pathname: 'https://wa.me/+255765903087' }}
                  target='_blank'
                >
                  <AiOutlineWhatsApp />
                </Link>
              </div>
            </Col>
          </Row>
          <h3 className='text-center mb-4  pt-4'>Our Offices</h3>
          <Row className='my-4'>
            <Col sm='12' md='3' className='my-2 branch'>
              Namanga Border Office,
              <br />
              Opposite TRA OSBP exit gate. <br />
              P.O. Box 8507, <br />
              Namanga - Tanzania. <br />
            </Col>
            <Col sm='12' md='3' className='my-2 branch'>
              Dar-Es-Salaam Tanzania Office, <br />
              Room 2, 6th floor, PPF House. <br />
              Samora / Morogoro road. <br />
              P.O. Box 5213 Dar-Es-Salaam. <br />
            </Col>
            <Col sm='12' md='3' className='my-2 branch'>
              Nairobi Kenya Office, <br />
              P.O. Box 15502 - 00400, <br />
              Tom Mboya Street, Nairobi. <br />
            </Col>
            <Col sm='12' md='3' className='my-2 branch'>
              Thika Kenya Office, <br />
              Ndururi House, Kisii road. <br />
              P.O. Box 6759 - 01000 Thika. <br />
              Kiambu County. <br />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contact;
