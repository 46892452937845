import React, { useReducer } from 'react';
import SiteContext from './siteContext';
import siteReducer from './siteReducer';

import {
  UPDATE_SECT_REF,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILED,
} from '../types';

const SiteState = (props) => {
  const initialState = {
    activeSection: 0,
    loading: false,
    message: '',
    errMessage: '',
  };

  const [state, dispatch] = useReducer(siteReducer, initialState);

  const updateSectRef = (ref) => {
    dispatch({ type: UPDATE_SECT_REF, payload: ref });
  };

  const sendMail = (email) => {};

  return (
    <SiteContext.Provider
      value={{
        activeSection: state.activeSection,
        updateSectRef,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export default SiteState;
