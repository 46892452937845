import React, { useState, useContext } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import SiteContext from './../../context/site/siteContext';
import { Logo } from '../../assets/images';

const Navigation = () => {
  const [show, setShow] = useState(false);

  const siteContext = useContext(SiteContext);

  const { activeSection } = siteContext;

  let landing = useMatch('/') && activeSection === 0;

  const handleClick = (e) => {
    // if (window.innerWidth <= 991.98) {
    setShow((prev) => !prev);
    // !e.currentTarget.classList.contains('collapsed')
    //   ? setShow(true)
    //   : setShow(false);
    // }
  };

  const getRandom = (min, max) => Math.floor(Math.random() * (max - min)) + min;

  return (
    <Navbar
      collapseOnSelect='true'
      expand='lg'
      bg={landing ? (show ? 'light' : 'transparent') : 'light'}
      fixed='top'
      variant={landing ? (show ? 'light' : 'dark') : 'light'}
    >
      <Container>
        <h1
          className='bg-light shadow'
          style={{ margin: 0, fontSize: '1rem', borderRadius: 20 }}
        >
          <Navbar.Brand as={Link} to='#' style={{ padding: 0 }}>
            <img
              className='logo'
              src={Logo}
              alt='PMchome Logistics'
              height='40'
            />
          </Navbar.Brand>
        </h1>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={handleClick}
        />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ml-auto'>
            <Nav.Link as={NavLink} to='/' eventKey={getRandom(1, 4)}>
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to='/about' eventKey={getRandom(4, 7)}>
              About
            </Nav.Link>
            <Nav.Link as={NavLink} to='/services' eventKey={getRandom(7, 10)}>
              Services
            </Nav.Link>
            <Nav.Link as={NavLink} to='/contact' eventKey={getRandom(10, 13)}>
              Contact
            </Nav.Link>
            {!landing && (
              <Nav.Link
                as={NavLink}
                to={{ pathname: '/contact' }}
                state={{ quote: 'true' }}
                eventKey={getRandom(13, 16)}
              >
                <Button
                  size='sm'
                  type='button'
                  variant='outline-warning'
                  className='btn'
                >
                  Get Quote
                </Button>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
