import {
  UPDATE_SECT_REF,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILED
} from './../types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_SECT_REF:
      return {
        ...state,
        activeSection: action.payload
      };
    case SEND_MAIL_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
        errMessage: ''
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        errMessage: ''
      };
    case SEND_MAIL_FAILED:
      return {
        ...state,
        loading: false,
        message: '',
        errMessage: action.payload
      };
    default:
      return state;
  }
};
