import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Mailto from 'react-protected-mailto';

const AlertModal = (props) => {
  const { status, quote, handleClose } = props;

  return (
    <Modal
      show
      onHide={handleClose}
      className={status ? 'modal-success' : 'modal-error'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {status
            ? quote
              ? 'Quote requested'
              : 'Message Sent'
            : quote
            ? 'Request Not Sent'
            : 'Message Not Sent'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {status ? (
          <>
            <p>
              Thank you for {quote ? 'your request!' : 'contacting us!'} We will
              be in touch with you shortly.
            </p>
            <p>Have a great day ahead!</p>
          </>
        ) : (
          <>
            <p>
              Sorry, there was an error while sending your{' '}
              {quote ? 'request' : 'message'}. Please try sending again.
            </p>
            <p>
              If the problem persists, kindly write to us through{' '}
              <Mailto email='info@pmchomelogistics.com' />
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
