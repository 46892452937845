import React from 'react';
import { useMatch } from 'react-router-dom';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Dar } from '../../assets/images';

const About = () => {
  let match = useMatch('/about');
  match ? console.log('true') : console.log('false');
  return (
    <>
      <Helmet>
        <title>About | PMchome Logistics</title>
        <link rel='canonical' href='https://pmchomelogistics.com/about' />
      </Helmet>{' '}
      <div className='main'>
        <div
          className='text-center xit'
          style={{
            backgroundImage: `url(${Dar})`,
            backgroundSize: 'cover',
          }}
        >
          <Container
            // style={{
            //   background: 'rgba(246,246,246,0.8  )',
            // }}
            fluid={true}
          >
            <h2 className='mb-4  pt-4'>Our Company</h2>
            <Row className='height12 justify-content-center'>
              <Col
                xs={10}
                sm={10}
                md={9}
                className='rounded-top'
                style={{
                  background: 'rgba(246,246,246,0.9)',
                }}
              >
                <p className='py-3'>
                  PMchome Logistics is the leading regional freight forwarding
                  company operating in Tanzania, Kenya, Uganda, Rwanda, Burundi,
                  South Sudan, Zambia and Malawi.
                </p>
                <p className='py-3 lead'>
                  We are involved in all fields of freight logistics with the
                  main emphasize on project forwarding.
                </p>
                <p className='py-3 text-justify'>
                  As your dependable and innovative logistics partner, we are
                  delighted to develop tailor-made solutions, helping you
                  achieve your goals. No matter what branch of trade or industry
                  you specialize in, our creativity and flexibility enables us
                  to offer you the best logistics services in East and Central
                  of Africa.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className=''>
          <Row className=' height34'>
            <Col sm={12} md={{ span: 8, order: 12 }}>
              <div className='align-items-center my-5'>
                <h4 className='mt-4 mb-2'>Our vision</h4>
                <p className='text-justify'>
                  To be the most reliable and efficient Clearing & Forwarding
                  service provider offering a broad spectrum of related
                  services, committed to excellence and renowned for our
                  integrity and ability to deliver
                </p>
              </div>
              <div className='my-5'>
                <h4 className='mt-4 mb-2'>Our mission</h4>
                <p className='text-justify'>
                  To build a long term relationship with our customers,
                  providing them with highest level of service delivery. To
                  address all our clients’ Clearing and Forwarding as well as
                  Transportation and Logistics needs, thereby sharing in their
                  vision and assisting by developing and realizing the vision
                  with them.
                </p>
              </div>
            </Col>
            <Col sm={12} md={{ span: 4, order: 1 }} className='bg-dotted'>
              <div
                className='position-relative pb-4'
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <h4 className='mt-4 mb-2'>Our slogan</h4>
                {/* <p className='lead'>Fast and Savoir of Traders.</p> */}
                <p className='lead'>
                  We never compromise on quality of service.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default About;
